<template>
  <div class="experience mb-5">
    <h1 class="title">My Experience</h1>
    <div class="container mb-5">
        <div class="content-wrapper">
            <div class="experience-item">
                <h3>
                  <span class="icon"><i class="fa-solid fa-arrow-right mr-4"></i></span>
                  <a class="card-title" @click="toggleCollapse('webdev1')">Web Developer at Webster University</a>
                </h3>
                <div v-if="collapsed.webdev1" class="collapse-content">
                    <h4 class="card-subtitle p-2">Sept 2022-present</h4>
                    <p class="card-text p-accordion"> In my current role at Webster University, I have designed, 
                    developed, and maintained a variety of custom applications that are critical to the university's 
                    operations. My work involves ensuring compliance with legal frameworks such as GDPR through a 
                    robust Cookie Consent Management System and managing scalable Azure infrastructure to support 
                    university-wide services. I also provide technical support to colleagues across both domestic and 
                    international campuses. Additionally, I have led the development of internal applications and 
                    spearheaded integration projects with third-party systems, ensuring seamless functionality for key 
                    university services. To foster long-term sustainability, I mentor colleagues and create 
                    comprehensive documentation and tutorials to ensure that applications are well-documented and 
                    colleagues can work on them confidently without risking disruptions or issues.
                    </p>
                </div>
            </div>

            <div class="experience-item">
                <h3>
                  <span class="icon"><i class="fa-solid fa-arrow-right mr-4"></i></span>
                  <a class="card-title" @click="toggleCollapse('webdev2')">Web Developer at Blue Cat Consulting</a>
                </h3>
                <div v-if="collapsed.webdev2" class="collapse-content">
                    <h4 class="card-subtitle p-2">Sept 2021-2022</h4>
                    <p class="card-text p-accordion"> 
                      Drawing on my expertise in full-stack development, I have single-handedly engineered 
                      tailored web solutions that brought my clients' visions to life. From conceptualizing ideas to 
                      fully developing and deploying websites, I have worked across platforms like WordPress, 
                      Squarespace, and custom-built HTML sites. When needed, I seamlessly integrated front-end and 
                      back-end technologies, providing clients with dynamic, responsive, and fully functional solutions. 
                      I personally handle every aspect of the development process, from initial consultation to final 
                      delivery to deliver high-quality results that help clients promote their products and grow their 
                      online presence.
                    </p>
                </div>
            </div>

            <div class="experience-item">
                <h3>
                  <span class="icon"><i class="fa-solid fa-arrow-right mr-4"></i></span>
                  <a class="card-title" @click="toggleCollapse('webdev3')">Freelance Developer</a>
                </h3>
                <div v-if="collapsed.webdev3" class="collapse-content">
                    <h4 class="card-subtitle p-2">Sept 2021-Present</h4>
                    <p class="card-text p-accordion"> 
                        I've applied my expertise in frameworks like Django, Ruby on Rails, and Flask, I have 
                        consistently delivered solutions that not only met clients' technical requirements but also 
                        materialized their creative visions. Through my work, I have effectively enhanced their online 
                        presence, whether by developing sites on platforms like WordPress, Squarespace, or custom 
                        full-stack frameworks. In addition to building websites, I’ve created APIs, automated tasks, 
                        and implemented robust back-end systems to ensure the seamless operation of their businesses. 
                        My approach integrates both technical proficiency and creative strategy, allowing me to design 
                        compelling, user-friendly platforms that drive tangible results for my clients.
                    </p>
                </div>
            </div>

            <div class="experience-item">
                <h3>
                  <span class="icon"><i class="fa-solid fa-arrow-right mr-4"></i></span>
                  <a class="card-title" @click="toggleCollapse('techstack')">Tech Stacks</a>
                </h3>
                <div v-if="collapsed.techstack" class="collapse-content">
                    <h4 class="card-subtitle p-2">These are the technologies I use as a Fullstack Developer</h4>
                    <p class="card-text p-accordion">Python, SQL (Postgres, MySQL and SQL Server), HTML, Vue, React, Bootstrap, JavaScript, JQuery, Django, Flask, SQLAlchemy,
                        Ruby, Rails, Turbo Stream, Node.js, Microsoft Azure, Docker, Nginx. 
                    </p>
                </div>
            </div>
        </div>
    </div>
  </div>
</template>


<script>
import TheNavbar from "../components/TheNavbar.vue";

export default {
  name: "ExperienceView",
  components: {
    TheNavbar,
  },
  data(){
    return {
      collapsed: {
        webdev1: false,
        webdev2: false,
        webdev3: false, 
        techstack: false,
      },
    };
  },
  methods: {
    toggleCollapse(section) {
      this.collapsed[section] = !this.collapsed[section];
    }
  }
};
</script>

<style scoped>
.experience {
    margin: auto;
    align-items: center;
    padding-top: 250px;
    max-height: calc(100vh - 100px); /* Adjust this value based on your navbar height */
    overflow-y: auto; /* Enable scrolling if content overflows */
    z-index:1;
    margin: 35px;

}

h1 {
    color: aliceblue;
    font-family: Papyrus;
    font-size: 45px;
}

h3 {
    color: aliceblue !important;
    text-decoration: none;
    font-family: Papyrus;
    font-size: 30px;
    margin-bottom: 10px; /* Space between titles */
}

h3:hover {
    text-decoration: underline;
}

a {
    color: aliceblue;
}

h4 {
    color: aliceblue !important;
    text-decoration: none;
    font-family: Papyrus;
    font-size: 25px;
}

p {
    color: aliceblue !important;
    text-decoration: none;
    font-family: Papyrus;
    font-size: 20px;
}

.collapse-content {
    transition: all 0.5s ease-in-out;
}

.content-wrapper {
    display: flex;
    flex-direction: column; /* Stack items vertically */
    align-items: center; /* Center horizontally */
    justify-content: center; /* Center vertically if height is set */
}

.experience-item {
    width: 100%; /* Use full width of the container */
   text-align: center;
    margin: 0px 400px; /* Vertical spacing between items */
    padding: 20px; /* Padding for each item */
    background-color: transparent; /* Optional: Add a background color for contrast */
    border-radius: 8px; /* Optional: Add rounded corners */
}
</style>
