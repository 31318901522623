<template>

  <div class="project-details" v-if="project">
    <div class="columns is-multiline">
      <div class="column">
        
        <div class="card">
          <h1 class="title">Project Details</h1>

      <div class="card-image">
        <figure class="image is-4by3">
          <img :src="project.imageUrl" alt="App image">
        </figure>
      </div>

      <div class="card-content">
        <div class="media">
          <div class="media-content">
            <h5 class="title is-5 mb-2">{{ project.name }}</h5>
            <p class="subtitle is-6">{{ project.technologies }}</p>
          </div>
        </div>
        
        <div class="content">
          <p>{{ project.description }}</p>

          <!-- Use external links instead of 'See More' -->
          <a :href="project.githublink" class="button is-normal is-link" target="_blank">Visit GitHub Repo</a>
          <a :href="project.appLink" class="button is-normal is-link" target="_blank">See the App</a>
        </div>
      </div>
    </div>
      </div>
    </div>
  </div>

</template>

<script>
import { doc, getDoc } from "firebase/firestore";
import { db } from "../firebase";
import { onMounted, ref } from 'vue';
import { useRoute } from "vue-router";

export default {
  setup() {
    const route = useRoute();
    const project = ref(null);
    const projectId = route.params.id;

    const fetchProject = async () => {
      try {
        const projectRef = doc(db, "projects", projectId);
        const projectSnap = await getDoc(projectRef);

        if (projectSnap.exists()) {
          project.value = projectSnap.data();
        } else {
          console.error('No project found');
        }
      } catch (error) {
        console.error("Error fetching your project:", error);
      }
    };

    onMounted(fetchProject);

    return {
      project,
    };
  },
};
</script>


<style scoped>

h1 {
    color: aliceblue;
    margin-top: 15rem;
    font-family: Papyrus;
    font-size: 45px;
}

 h5 {
    color: aliceblue;
    text-decoration: none;
    font-family: Papyrus;
    font-size: 30px;
  }

  p {
    color: aliceblue !important;
    font-family: Papyrus;
  }

  button {
    background-color: transparent;
  }

.project-details {
  padding-top: 350px;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  padding: 0 20px;  /* Adjust based on your navbar */
  margin: 35px;
  max-height: calc(100vh - 100px); /* Adjust this value based on your navbar height */
 
  color: white;
}

.project-img{
  
  object-fit: cover; /* Ensure images maintain aspect ratio and cover the entire area */
  max-width: 350px;
  max-height: 350px;;
  border-radius: 8px;
  
}

.card {
  background-color: transparent; /* Semi-transparent for effect */
  border-radius: 8px;
  padding: 20px;
  max-width: 500px;

}


.card-image {
  max-height:450px;
  max-width: 450px;
  margin: 0 auto;
}


.media-content {
  color: white;
}

.content p {
  margin-bottom: 15px;
  color: aliceblue
}

.button.is-link {
  background-color: transparent; /* Adjust button color */
  border: none;
  padding: 10px 15px;
  color: white;
  border-radius: 5px;
  transition: background-color 0.2s;
}

.button.is-link:hover {
  text-decoration: underline;  /* Darker hover effect */
}



</style>
