<template>
  <section class="hero is-fullheight hcf-overlay hcf-bs-cover hcf-bp-center">
    <div class="hero-body py-10 mb-8">
      <div class="container has-text-centered">
        <img
          class="mb-6 logo is-hidden-mobile"
          src="https://firebasestorage.googleapis.com/v0/b/portfolio-2d12e.appspot.com/o/logo.png?alt=media&token=9da5366f-2696-4a0e-95f6-a6282382b17c"
          alt="logo"
          width="300"
          height="300"
        />
        <img
          class="mb-6 logo is-hidden-tablet is-hidden-desktop"
          src="https://firebasestorage.googleapis.com/v0/b/portfolio-2d12e.appspot.com/o/logo.png?alt=media&token=9da5366f-2696-4a0e-95f6-a6282382b17c"
          alt="logo"
          width="200"
          height="200"
        />
        <p class="p-text mb-9 is-size-6-mobile is-size-5-tablet is-size-4-desktop">
          Hello there! My name is Marilyn Marquez, and I am a passionate Full Stack Developer with a strong background in Django and Ruby on Rails, a knack for backend development, and a passion for efficient, scalable systems. Explore my portfolio to see how I've leveraged Django and Ruby on Rails to create amazing web applications, and let's connect to discuss how I can help you achieve your goals!
        </p>
        <br><br>
        <div class="buttons is-centered">
          <router-link to="/projects" class="button is-outlined is-large text-white">My Projects</router-link>
          <router-link to="/experience" class="button is-outlined is-large text-white">Experience</router-link>
        </div>
      </div>
    </div>
  </section>
</template>

<style scoped>



.is-outlined{
  color: aliceblue
}

  .button{
  background-color: aliceblue;
  color: darkblue;
  margin-right: 15px;
}

.button:hover {
  color: aliceblue;
  background-color: darkblue;
}


</style>