<template>
  <nav class="navbar hcf-overlay is-dark mb-5">
    <div class="container-fluid is-flex is-align-items-center">
      <router-link class="navbar-brand" to="/">
        <img 
          src="https://firebasestorage.googleapis.com/v0/b/portfolio-2d12e.appspot.com/o/logo.png?alt=media&token=9da5366f-2696-4a0e-95f6-a6282382b17c" 
          alt="Logo image" 
          width="110" 
          height="110" 
          class="logo"/>         
      </router-link>

      <a class="navbar-burger" aria-label="menu" aria-expanded="false" data-target="navbar-menu" @click="toggleNavbar">
        <span aria-hidden="true"></span>
        <span aria-hidden="true"></span>
        <span aria-hidden="true"></span>
      </a>

      <div class="navbar-menu" :class="{ 'is-active': isNavbarActive }">
        <div class="navbar-start is-flex is-align-items-center" :class="{ 'is-hidden': !isNavbarActive }">
          <router-link 
            class="navbar-item nav-link" 
            to="/projects" 
            :class="{ active: $route.path === '/projects' }">
            Projects
          </router-link>

          <router-link 
            class="navbar-item nav-link" 
            to="/experience" 
            :class="{ active: $route.path === '/experience' }">
            Experience
          </router-link>

          <a class="navbar-item nav-link" href="https://www.linkedin.com/in/marilyn-marquez-056240220/" target="_blank">LinkedIn</a>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
export default {
  data() {
    return {
      isNavbarActive: false,
      isMobile: false
    };
  },
  methods: {
    toggleNavbar() {
      this.isNavbarActive = !this.isNavbarActive;
    },
    handleResize() {
      this.isMobile = window.innerWidth < 768;
    }
  },
  mounted() {
    window.addEventListener('resize', this.handleResize);
    this.handleResize();
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize);
  }
}
</script>

<style scoped>
.navbar {
  background-color: rgba(2, 2, 38, 0.65);
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  margin-bottom: 50px;
}

.navbar-item {
  color: aliceblue;
  font-family: "Papyrus", "Courier New", monospace;
  font-size: 30px;
  margin: 10px 0;
  text-decoration: none;
}

.navbar-link {
  background-color: transparent !important;
}

.navbar-item:hover {
  background-color: transparent !important;
  font-weight: bolder !important;
  text-decoration: underline;
}

.navbar-menu.is-active {
  background-color: rgba(2, 2, 38, 0.7); /* Set background for dropdown */
  display: flex; /* Ensure flex for vertical alignment */
  flex-direction: column; /* Stack links vertically */
  padding: 10px; /* Optional: Add some padding */
  align-items: center; /* Center links horizontally */
}

.navbar-start {
  flex-direction: row;
}

.navbar-brand {
  margin-left: 20px;
}

.navbar-brand-img {
  width: 110px;
  height: 110px;
}

@media only screen and (max-width: 768px) {
  .navbar-menu.is-active {
    flex-direction: column;
  }
  .navbar-start {
    flex-direction: column;
  }
  .navbar-item {
    font-size: 20px;
    margin-bottom: 10px;
  }
  .navbar-brand {
    display: block;
    margin: 0 auto;

  }
  .navbar-brand img {
    width: 75px;
    height: 75px;
  }

}

</style>