import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import ExperienceView from '../views/ExperienceView.vue'
import ProjectsView from '../views/ProjectsView.vue'
import ProjectDetails from '../views/ProjectDetails.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: HomeView,
    meta: { showNavbar: false }
  },
  {
    path: '/experience',
    name: 'Experience',
    component: ExperienceView,
    meta: { showNavbar: true }
  },
  {
    path: '/projects',
    name: 'Projects',
    component: ProjectsView,
    meta: { showNavbar: true }
  },
  {
    path: '/project-details/:id',
    name: 'ProjectDetails',
    component: ProjectDetails,
    meta: { showNavbar: true }
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
