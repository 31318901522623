// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyCSRt2YqLZ6UF-HDagKOUC-bu4nz_jg28g",
  authDomain: "portfolio-2d12e.firebaseapp.com",
  projectId: "portfolio-2d12e",
  storageBucket: "portfolio-2d12e.appspot.com",
  messagingSenderId: "757883180184",
  appId: "1:757883180184:web:cda22b5c41618ebedb2ced"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

const db = getFirestore(app);
export { db }