<template>
  <div id="app">
    <!-- Spinner -->
    <div v-if="loading" class="loading-overlay">
      <div class="loader"></div>
    </div>

    <!-- Navbar and content -->
    <TheNavbar v-if="showNavbar" />
    <router-view />
  </div>
</template>

<script>
import TheNavbar from './components/TheNavbar.vue';
import { computed, ref, onMounted, onUnmounted } from 'vue';
import { useRoute, useRouter } from 'vue-router';

export default {
  name: "App",
  components: {
    TheNavbar,
  },
  setup() {
    const route = useRoute();
    const showNavbar = computed(() => route.meta.showNavbar);

    const loading = ref(false); // Spinner state

    // Watch for route changes to show/hide spinner
    const router = useRouter();

    const startLoading = () => {
      console.log("Starting loading..."); // For debugging
      loading.value = true;
    };

    const stopLoading = () => {
      console.log("Stopping loading..."); // For debugging
      loading.value = false;
    };

    // Use navigation guards to control spinner
    onMounted(() => {
      router.beforeEach((to, from, next) => {
        startLoading();
        next();
      });

      router.afterEach(() => {
        setTimeout(() => {
          stopLoading();
        }, 1000); // 1 second delay for testing
      });
    });

    onUnmounted(() => {
      router.beforeEach(null);  // Clean up listeners
      router.afterEach(null);
    });

    return { showNavbar, loading };
  },
};
</script>

<style lang="scss">
html, body {
  height: 100%;
  margin: 0;
  padding: 0;
  background-color: #000;
  overflow-x: hidden;
}

body {
  background: linear-gradient(180deg, #000346 0%, #6cfae7 100%), 
              linear-gradient(58.72deg, #0029FF 0%, #aa0047 100%), 
              radial-gradient(100% 164.72% at 100% 100%, #FF00A8 0%, #00FF47 100%), 
              radial-gradient(100% 148.07% at 0% 0%, #FFF500 0%, #51D500 100%);
  height: 100vh;
  background-blend-mode: overlay, overlay, difference, normal;
  background-attachment: fixed;
  color: aliceblue !important;
}

#app {
  min-height: 100vh; 
  display: flex;
  flex-direction: column;
  font-family: Papyrus;
  text-align: center;
  color: aliceblue;
}

nav {
  padding: 30px;
}

a {
  font-weight: bold;
  color: aliceblue;
}

.is-outlined {
  color: aliceblue;
}

button {
  color: aliceblue;
}

a.router-link-exact-active {
  color: aliceblue;
}

/* Loading spinner styles */
.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #000346; 
  z-index: 9999;
  opacity: 1 !important; /* Force visibility for testing */
}

.loader {
  border: 5px solid #f3f3f3;
  border-top: 5px solid #3498db;
  border-radius: 50%;
  width: 50px !important;
  height: 50px !important;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
</style>
