<template>
    <div class="projects">
            <h1 class="title mb-5">Projects</h1>
            <br><br>
            <div class="columns is-multiline">
                <div class="column is-one-third mb-5" v-for="project in projects" :key="project.id">
                    <div class="card" >
                        <div class="card-image">
                            <figure class="image is-4by3">
                                <img :src="project.imageUrl" alt="App image">
                            </figure>
                        </div>
                        <div class="card-content" style="background-color: transparent; color: white;">
                           <div class="media">
                                <div class="media-content">
                                    <h5 class="title is-5 mb-2">{{ project.name }}</h5>
                                    <p class="subtitle is-6">{{ project.technologies }}</p>
                                </div>
                            </div>
                            <div class="content">
                                <p>{{ truncateDescription(project.description, 120) }}</p>
                                    <router-link :to="{ name: 'ProjectDetails', params: { id: project.id } }" class="is-link">
                                        See More
                                    </router-link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
</template>


<script>
import TheNavbar from "../components/TheNavbar.vue";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../firebase";

export default {
    components: {
        TheNavbar
    },
    data() {
        return {
            projects:[]
        };
    },
     async created() {
    try {
      const querySnapshot = await getDocs(collection(db, "projects"));
      const projectsArray = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      this.projects = projectsArray;
    } catch (error) {
      console.error("Error fetching projects: ", error);
    }
  },
      methods: {
        truncateDescription(description, maxLength) {
            if (description.length > maxLength) {
                return description.substring(0, maxLength) + '...'; 
            }
            return description;
        }
    },
};
</script>


<style scoped>

    .projects {
    padding-top: 150px;
    max-height: calc(100vh - 100px); /* Adjust this value based on your navbar height */
     /* Enable scrolling if content overflows */
    z-index:1;
    margin: 35px;
    }



    h1{
        color: aliceblue;
        font-family: Papyrus;
        font-size: 45px;
    }

    h3 {
    color: aliceblue !important;
    text-decoration: none;
    font-family: Papyrus;
    font-size: 30px;
  }

  h3:hover {
    text-decoration: underline;
  }

  h5 {
    color: aliceblue;
    font-family: Papyrus;
    font-size: 25px;
  }

  a{
    color:aliceblue;
  }

  h4 {
    color: aliceblue !important;
    text-decoration: none;
    font-family: Papyrus;
    font-size: 25px;
  }

  p {
    color: aliceblue !important;
    text-decoration: none;
    font-family: Papyrus;
    font-size: 20px;
  }

  .is-link {
    color: aliceblue;
    font-family: Papyrus;
  }

  .is-link:hover {
    text-decoration: underline;
  }

.card {
  height: 40rem;
  width: 28rem;
  background-color: transparent;
  margin: 0 auto;
  
}

.card-img-top {
  height: 20rem; /* Adjust the image height as needed */
  width: auto;
  object-fit: cover; /* Ensure images maintain aspect ratio and cover the entire area */
}

.columns {
  justify-content: center;
}

.column {
  padding-left: 1rem;
  padding-right: 1rem;
}

</style>

